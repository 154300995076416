<template>
  <div ref="wrapperEl" class="relative h-full w-full">
    <video
      ref="videoEl"
      class="h-full w-full object-cover"
      :src="source"
      loop
      autoplay
      muted
    />
  </div>
</template>

<script lang="ts" setup>
import { SanityAsset } from '@/types/sanity';
import { videoSource } from '@/util/sanity';

const props = defineProps<{
  asset: SanityAsset;
}>();

const sanityImage = useSanityImage();

const source = computed(() => {
  return videoSource(sanityImage.options, props.asset.asset);
});

const isLoaded = ref(false);
const wrapperEl = ref<HTMLDivElement | null>(null);
const videoEl = ref<HTMLVideoElement | null>(null);

const loadVideo = () => {
  videoEl.value!.src = source.value;
  videoEl.value!.onload = async () => {
    isLoaded.value = true;
  };
};

let observer: IntersectionObserver;
onMounted(() => {
  if (!isLoaded.value) {
    observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        loadVideo();
      }
    });
    observer.observe(wrapperEl.value!);
  }
});

onUnmounted(() => {
  observer?.disconnect();
});
</script>
